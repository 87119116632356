import { useState } from 'react'


//components
import Navbar from '../../components/navigation/Navbar'
import CoverPhoto from '../../components/photos/CoverPhoto'
import PhotoSelector from '../../components/photos/PhotoSelector'
import InputText from '../../components/inputs/InputText'
import InputTextarea from '../../components/inputs/InputTextarea'
import LargeDropdown from '../../components/inputs/LargeDropdown'
import SmallDropdown from '../../components/inputs/SmallDropdown'

//lists components
import CategoriesListSelector from '../../components/foreground/lists/CategoriesListSelector'
import SubCategoriesListSelector from '../../components/foreground/lists/SubCategoriesListSelector'
import EntriesListSelector from '../../components/foreground/lists/EntriesListSelector'


//styles
import styles from './CreatePost.module.css'




export default function CreatePost() {

    // selection lists state
    const [showLists, setShowLists] = useState(false)
    
    
    const [showEntries, setShowEntries] = useState(false)
    

    
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [selectedEntry, setSelectedEntry] = useState(null)


    const handleShowLists = () => {
        if (showLists) {
            setShowLists(false)
            
        } else {
            setShowLists(true)
            
        }
    }

    const handleSelectedCategory= (id) => {
        
        setSelectedCategory(id)
    }

    const handleSelectedSubCategory= (id) => {
        setSelectedSubCategory(id)
    }

    const handleSelectedEntry= (id) => {
        setSelectedEntry(id)
    }

    
    return (
        
        <div className={styles['create-post']} id="create-container">
            { !showLists && <Navbar title="Adauga anunt"/> }

            <div className={styles['cover-wrapper']}>    
                <CoverPhoto />
                <PhotoSelector />
            </div>

            <div className={styles.form}>
                <div className="container">
                    <InputText placeholder="Titlu" hasAsterix={true} verify= {true} validity={{ minimum:4, maximum:30 }}/>
                    <InputTextarea placeholder="Descriere" hasAsterix={true} verify= {true} validity={{ minimum:20, maximum:500 }}/>
                    <LargeDropdown  handleShow={handleShowLists}/>

                    <div className="input-group">
                        <InputText placeholder="Pret" hasAsterix={true} verify= {true} validity={{ minimum:4, maximum:30 }}/>
                        <SmallDropdown />
                    </div>

                    

                </div>
            </div>
            
            <div className={showLists ? styles['show-list'] : styles['hide-list']}>
                {
        
                    <CategoriesListSelector 
                        handleSelectedCategory={handleSelectedCategory}
                        selectedCategory={selectedCategory}
                        handleShowLists={handleShowLists}
                        showLists={showLists}
                        
                    />
                
                }

                {

                    <SubCategoriesListSelector
                    handleSelectedSubCategory={handleSelectedSubCategory}
                    handleShowLists={handleShowLists}
                    selectedSubCategory={selectedSubCategory}
                    selectedCategory={selectedCategory}
                    />
                    
                }

                {showEntries && 

                    <EntriesListSelector 
                    handleSelectedEntry={handleSelectedEntry}
                    />

                }
            </div>
        </div>
    )
}
