//styles
import styles from './SmallDropdown.module.css'

export default function SmallDropdown() {
    return (
        <div className="input-wrapper">
            <div className={styles['small-dropdown']}></div>
        </div>
    )
}
