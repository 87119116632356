//styles
import styles from './CheckText.module.css'

export default function CheckText({text}) {
    return (
        <div className={styles['check-text']}>
            <span className={styles['success']}>{text}</span>
        </div>
    )
}
