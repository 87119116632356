import { useState } from 'react'

//styles
import styles from './SelectionNav.module.css'

export default function SelectionNav({title, handleCurrentList, handleClick}) {

    
    
    

    return (
        <nav className={styles['selection-nav']}>
            <div className="container">
                <div className={styles['selection-nav-flex']}>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="arrow-left" viewBox="0 0 16 16" onClick={handleClick}>
                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0
                        0 1-.708.708l-6-6a.5.5 0 0 1 
                        0-.708l6-6a.5.5 0 0 1 .708 0z"
                        />
                    </svg>
                    
                    <div className={styles['nav-item']}>
                        {title}
                    </div>
                </div>
            </div>
        </nav>
    )
}
