import ReactDOM from 'react-dom'
import { useState, useEffect } from 'react'
import { useFetch } from '../../../hooks/useFetch'

//components
import SelectionNav from '../../navigation/SelectionNav'
import SubCategoryListItem from '../../listItems/SubCategoryListItem'

//styles
import styles from './SubCategoriesListSelector.module.css'


export default function SubCategoriesListSelector({handleSelectedSubCategory, selectedSubcategory,selectedCategory, showLists, handleShowLists}) {

    const [showSubCategories, setShowSubCategories] = useState(false)
    const [subcategories, setSubcategories] = useState([])

    const {postData, data, error} = useFetch("https://dev.anunturi66.ro/api/subcategories", "POST")

    const handleShowSubCategories = () => {
        if (showSubCategories) {
            setShowSubCategories(false)
        } else {
            setShowSubCategories(true)
        }
        
    }

    useEffect(()=> {

        if (selectedCategory) {
            postData(selectedCategory)
        }

    }, [selectedCategory])

    return (
        <>
            {showSubCategories &&
            
            <ul className={styles['sublist-selector']}>

                {showLists &&
                    ReactDOM.createPortal(<SelectionNav title="Selecteaza categoria" handleClick={handleShowLists}/>, document.body)
                }

                { subcategories.map((item) => <SubCategoryListItem 
                                            key={item.id} item={item} 
                                            handleSelectedSubCategory={handleSelectedSubCategory} 
                                            selectedSubcategory={selectedSubcategory}
                                            handleShowCategories={handleShowSubCategories}
                                            /> )
                
                }

            </ul>
            
            }
        </>
    )
}
