//styles
import styles from './DynamicPlaceholder.module.css'

export default function DynamicPlaceholder({placeholder, hasAsterix, isFocused, inputValue}) {

    let placeholderClass = `${styles['dynamic-placeholder']}`
    
    if (isFocused || inputValue) {
        placeholderClass += ` ${styles['retracted']}`
    }

    return (
        <div className={placeholderClass}>
            <div className={styles['content']}>
                <span>{placeholder}</span>
                {hasAsterix && (
                    <span className={styles['required-asterix']}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-asterisk" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 
                            1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 
                            0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z">
                            </path>
                        </svg>
                    </span>
                )}
            </div>
        </div>
    )
}
