
//components
import addCamera from '../assets/add-photo.png'
import photosIcon from '../assets/photos.svg'

//styles
import styles from './PhotoSelector.module.css'

export default function PhotoSelector() {
    return (
        <div className={styles['photo-selector']}>
            <div className={styles['add-photo-icon']}>
                <img
                alt="add-camera-icon"
                src={addCamera}
                />
            </div>
            <div className={styles['add-photo-instructions']}>
                <div className={styles['title']}>
                    <div className={styles['add-photo-i']}>
                        <img
                        alt="images-bundle"
                        src={photosIcon}
                        />
                    </div>
                    <span>Adauga foto</span>
                </div>
                <div className={styles['counter']}>
                    <span>Utilizezi </span>
                    <span>0 din </span>
                    <span>0</span>
                </div>
            </div>
        </div>
    )
}
