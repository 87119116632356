
//styles
import styles from './Navbar.module.css'

export default function Navbar({title}) {
    return (
        <nav className={styles.navbar}>
            <div className="container">
                <div className={styles['navbar-flex']}>
                    <div className={styles.item}>
                        <div className={styles['navbar-group']}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="close-x">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                                </svg>
                            </div>
                            <div>
                                <div className={styles['navbar-title']}>
                                    <span>{title}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.item}></div>
                </div>
            </div>
        </nav>
    )
}
