import ReactDOM from 'react-dom'
import { useState } from 'react'

//components
import CategoryListItem from '../../listItems/CategoryListItem'
import SelectionNav from '../../navigation/SelectionNav'

//styles
import styles from './CategoriesListSelector.module.css'

const categories = [
    {
        id: 1,
        name: "Imobiliare",
        color: "#993300ff",
        subColor: "#ffece2",
        src: "/img/categories/home.svg"
    },
    {
        id: 2,
        name: "Auto-Moto",
        color: "#327fb5ff",
        subColor: "#e6f3fc",
        src: "/img/categories/car.svg"
    },
    {
        id: 3,
        name: "Electro & IT",
        color: "#6dcdbcff",
        subColor: "#e2fef9",
        src: "/img/categories/phone.svg"
    },
    {
        id: 4,
        name: "Afacaeri si servicii",
        color: "#666699ff",
        subColor: "#f0f0ff",
        src: "/img/categories/deal.svg"
    },
    {
        id: 5,
        name: "Casa si gradina",
        color: "#fb8636ff",
        subColor: "#fff3ea",
        src: "/img/categories/sofa.svg"
    },
    {
        id: 6,
        name: "Mama si copilul",
        color: "#e1c33fff",
        subColor: "#fff9e0",
        src: "/img/categories/baby.svg"
    },
    {
        id: 7,
        name: "Moda si sanatate",
        color: "#c96b9fff",
        subColor: "#ffe8f5",
        src: "/img/categories/fashion.svg"
    },
    {
        id: 8,
        name: "Pet Shop",
        color: "#8cae60ff",
        subColor: "#f2fce5",
        src: "/img/categories/pet.svg"
    },
    {
        id: 9,
        name: "Matrimoniale",
        color: "#e54b6dff",
        subColor: "#ffe8ed",
        src: "/img/categories/heart.svg"
    },
    {
        id: 10,
        name: "Timp liber & sport",
        color: "#1fc4f3ff",
        subColor: "#edfafe",
        src: "/img/categories/ball.svg"
    },
    {
        id: 11,
        name: "Agricole",
        color: "#A4CE73",
        subColor: "#f4ffe7",
        src: "/img/categories/tractor.svg"
    },
    {
        id: 12,
        name: "Diverse",
        color: "#969696ff",
        subColor: "#f0f0f0",
        src: "/img/categories/cart.svg"
    },
]

export default function CategoriesListSelector({selectedCategory, handleSelectedCategory, handleShowLists, showLists}) {

    const [showCategories, setShowCategories] = useState(true)

    const handleShowCategories = () => {
        if (showCategories) {
            setShowCategories(false)
        } else {
            setShowCategories(true)
        }
        
    }

    return (

        <>
            {showCategories &&
            
            <ul className={styles['list-selector']}>

                {showLists &&
                    ReactDOM.createPortal(<SelectionNav title="Selecteaza categoria" handleClick={handleShowLists}/>, document.body)
                }

                { categories.map((item) => <CategoryListItem 
                                            key={item.id} item={item} 
                                            handleSelectedCategory={handleSelectedCategory} 
                                            selectedCategory={selectedCategory}
                                            handleShowCategories={handleShowCategories}
                                            /> )
                
                }

            </ul>
            
            }
        </>
        

    )
}


