

//styles
import styles from './CategoryListItem.module.css'



export default function CategoryListItem({item, handleSelectedCategory, selectedCategory}) {

    

    const makeSelection = () => {

        handleSelectedCategory(item)

        // setTimeout(() => {
        //     handleCurrentList("subcategories")

        // }, 100)
    }

    return (
        <li className={styles['compact-item']} onClick={makeSelection}>
            <img 
            alt={item.src}
            src={item.src}
            />
            <span>{item.name}</span>
            {selectedCategory !== item.id ? 
            
            <span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 
                    .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
            </span>

            :

            <span className="check-mark">
                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 
                    0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 
                    11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z">

                    </path>
                </svg>
            </span>

            }
        </li>
    )
}
