import { useState } from 'react'

//components
import DynamicPlaceholder from './DynamicPlaceholder'
import CheckText from './CheckText'

//styles
import styles from './InputText.module.css'

export default function InputText({placeholder, hasAsterix, validity}) {

    const [isFocused, setIsFocused] = useState(false)
    const [value, setValue] = useState('')
    const [checkText, setCheckText] = useState(null)
    const [isValid, setIsValid] = useState(false)
    

    //initial styles
    let inputClass = `${styles['input-text']}`

    const handleClick = (e) => {
       e.currentTarget.children[0].focus()
    }

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = (e) => {
        const text = e.target.value
        if (text.length < validity.minimum) {
            setIsValid(false)
            setCheckText("Campul " + placeholder + " trebuie sa contina cel putin "+ validity.minimum +" caractere")
        }

        setIsFocused(false)
    }

    const handleChange = (e) => {
        const text = e.target.value
        
        if (text.length < value.length && text.length < validity.minimum) {
            setIsValid(false)
            setCheckText("Campul " + placeholder + " trebuie sa contina cel putin "+ validity.minimum +" caractere")
        }
        
        else if(text.length >= validity.minimum) {
            setIsValid(true)
        }
        else {
            setCheckText(null)
        }

        
        setValue(text)

    }

    

    if (checkText) {
        inputClass += ` ${styles['error-border']}`
    }

    if (isValid) {
        inputClass += ` ${styles['success-border']}`
    }

    if (isFocused) {
        inputClass += ` ${styles['selected-border']}`
        inputClass += ` ${styles['focused']}`
    }

    if (!isFocused && isValid) {
        inputClass += ` ${styles['neutral-border']}`
    }
    

    return (
        <div className="input-wrapper">
            <div className={inputClass} onClick={handleClick}>
                <input type="text" 
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={value}
                />
                <DynamicPlaceholder 
                    placeholder={placeholder} 
                    hasAsterix = {hasAsterix}
                    isFocused = {isFocused}
                    inputValue = {value}
                />
            </div>

            <CheckText text={checkText}/>

        </div>
          
    )
    
}
