//components


//styles
import styles from './SubCategoryListItem.module.css'

export default function SubCategoryListItem({item, handleSubSelectedCategory, selectedSubCategory, handleCurrentList}) {

    return (
        <li className={styles['sub-list-item']}>
            Loading
            
            {item && item.name}
        </li>
    )
}
